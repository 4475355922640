var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0 20px","height":"100%"}},[_c('div',{staticClass:"flexWrap menuStyle",staticStyle:{"border-radius":"20px","padding":"10px","background-color":"whiter"}},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:index,staticClass:"menuItemStyle",on:{"click":function($event){item.title != '扫码上传' ? _vm.menuBtn(item) : _vm.sys(item)}}},[_c('div',[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"menuStyle",staticStyle:{"padding":"0 20px","margin":"10px 0 40px","overflow":"auto","height":"50%","border-radius":"15px"}},[_vm._m(0),(_vm.publicityList.length <= 0)?_c('div',{staticStyle:{"padding":"20px","color":"#0094ff","text-align":"center"}},[_vm._v(" 暂无通知... ")]):(_vm.publicityList)?_c('div',{staticStyle:{"overflow":"auto","width":"100%"}},_vm._l((_vm.publicityList),function(item,index){return _c('div',{key:index,staticStyle:{"margin":"15px 0","overflow":"auto"}},[_c('div',{staticStyle:{"height":"40px","line-height":"20px"},on:{"click":function($event){_vm.$Dialog
              .alert({
                title: item.pmsg02,
                message: item.pmsg05,
              })
              .then(function () {
                // on close
              })}}},[_c('div',{staticClass:"flexLR",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"flexLR",staticStyle:{"width":"90%","justify-content":"left","height":"100%"}},[_c('img',{attrs:{"src":require("../../../assets/mobileImg/项目通知.png"),"alt":""}}),_c('div',{staticClass:"flexcolumn",staticStyle:{"height":"100%","width":"100%","align-items":"flex-start","margin-left":"10px"}},[_c('div',{staticClass:"nowrap",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(item.pmsg02)+" ")]),_c('div',{staticStyle:{"color":"#a2a2a2"}},[_vm._v("发布时间："+_vm._s(item.pmsg07))])])]),_c('div',[_c('van-icon',{attrs:{"name":"arrow"}})],1)])])])}),0):_c('div',{staticClass:"announcementItem",staticStyle:{"text-align":"center"}},[_vm._v(" 暂无公告 ")])]),_c('van-popup',{attrs:{"position":"bottom","get-container":"body"},model:{value:(_vm.modelShow),callback:function ($$v) {_vm.modelShow=$$v},expression:"modelShow"}},[_c('van-picker',{attrs:{"title":"选择节点","show-toolbar":"","columns":_vm.modelList,"value-key":"label"},on:{"confirm":_vm.onConfirmModel,"cancel":function($event){_vm.modelShow = false}}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuStyleTitle flex",staticStyle:{"justify-content":"left"}},[_c('h1',[_vm._v("项目通知")])])}]

export { render, staticRenderFns }