<template>
  <div style="padding: 0 20px; height: 100%">
    <div
      class="flexWrap menuStyle"
      style="border-radius: 20px; padding: 10px; background-color: whiter"
    >
      <div
        v-for="(item, index) in menuList"
        @click="item.title != '扫码上传' ? menuBtn(item) : sys(item)"
        class="menuItemStyle"
        :key="index"
      >
        <div><img :src="item.icon" alt="" /></div>
        <div>{{ item.title }}</div>
      </div>
    </div>
    <div
      class="menuStyle"
      style="
        padding: 0 20px;
        margin: 10px 0 40px;
        overflow: auto;
        height: 50%;
        border-radius: 15px;
      "
    >
      <div class="menuStyleTitle flex" style="justify-content: left">
        <h1>项目通知</h1>
      </div>
      <div
        v-if="publicityList.length <= 0"
        style="padding: 20px; color: #0094ff; text-align: center"
      >
        暂无通知...
      </div>
      <div v-else-if="publicityList" style="overflow: auto; width: 100%">
        <div
          v-for="(item, index) in publicityList"
          :key="index"
          style="margin: 15px 0; overflow: auto"
        >
          <div
            @click="
              $Dialog
                .alert({
                  title: item.pmsg02,
                  message: item.pmsg05,
                })
                .then(() => {
                  // on close
                })
            "
            style="height: 40px; line-height: 20px"
          >
            <div class="flexLR" style="height: 100%">
              <div
                class="flexLR"
                style="width: 90%; justify-content: left; height: 100%"
              >
                <img src="../../../assets/mobileImg/项目通知.png" alt="" />
                <div
                  style="
                    height: 100%;
                    width: 100%;
                    align-items: flex-start;
                    margin-left: 10px;
                  "
                  class="flexcolumn"
                >
                  <div class="nowrap" style="width: 90%">
                    {{ item.pmsg02 }}
                  </div>
                  <div style="color: #a2a2a2">发布时间：{{ item.pmsg07 }}</div>
                </div>
              </div>
              <div><van-icon name="arrow" /></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="announcementItem" style="text-align: center">
        暂无公告
      </div>
      <!-- <input value="123" type="file">666</input> -->
      <!-- <a href="http://192.168.1.237:64305//UploadFiles/Company/50/Project/130/FormApply/38/c302096f-7187-4906-8540-ac9716d12141.doc">IMG</a> -->
    </div>

    <van-popup v-model="modelShow" position="bottom" get-container="body">
      <van-picker
        title="选择节点"
        show-toolbar
        :columns="modelList"
        @confirm="onConfirmModel"
        @cancel="modelShow = false"
        value-key="label"
      />
    </van-popup>
  </div>
</template>

<script>
import { GetProjectMsg, GetWorkPlaneTree2 } from "@/api/index";
import { mapGetters } from "vuex";
export default {
  name: "dd_menu",
  components: {},
  data() {
    return {
      modelList: [],
      modelShow: false,
      publicityList: [],
      menuList: [
        {
          title: "项目详情",
          icon: require("../../../assets/mobileImg/项目详情.png"),
          path: "/home/menu/projectInfo",
          // path: "",
        },
        {
          title: "进度上报",
          icon: require("../../../assets/mobileImg/进度上报.png"),
          path: "/home/menu/schedule",
        },
        {
          title: "工程资料",
          icon: require("../../../assets/mobileImg/工程资料.png"),
          path: "/home/menu/engineering",
        },
        {
          title: "实景上传",
          icon: require("../../../assets/mobileImg/实景上传.png"),
          path: "/home/menu/scenery",
        },
        {
          title: "扫码上传",
          icon: require("../../../assets/mobileImg/扫码上传.png"),
          path: "/Survey",
        },
        {
          title: "模型浏览",
          icon: require("../../../assets/mobileImg/模型浏览.png"),
          path: "/model",
        },
        {
          title: "安全管理",
          icon: require("../../../assets/mobileImg/安全巡查.png"),
          path: "/home/menu/SecurityPatrol",
        },
        {
          title: "质量管理",
          icon: require("../../../assets/mobileImg/质量管理.png"),
          path: "/home/menu/QualityPatrol",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["qid", "pid"]),
  },
  watch: {},
  methods: {
    onConfirmModel(v) {
      this.$router.push({ path: "/model", query: { id: v.ID } });
      this.modelShow = false;
    },
    sys(v) {
      wx.miniProgram.navigateTo({
        url: `/pages/scanCode/scanCode`,
      });
    },
    // 模型
    async GetModelList() {
      let res = await GetWorkPlaneTree2({
        xid: this.pid,
        pid: -1,
      });
      res.Data.length > 0 ? (this.modelList = res.Data) : "";
    },
    updateData() {
      this.GetInfo();
      this.$emit("updateLoading", "项目通知");
    },
    menuBtn(item) {
      if (item.title == "模型浏览") {
        if (this.modelList.length > 0) {
          this.modelShow = true;
        } else {
          this.$Toast.fail("暂无节点");
        }
      } else {
        this.$router.push({ path: item.path, params: { ...item } });
      }
    },
    async GetInfo() {
      let tzRes = await GetProjectMsg({
        params: {
          id: this.$store.state.pid,
          pageIndex: 1,
          pageSize: 1000,
        },
      });
      if (tzRes.Code > 0) this.publicityList = tzRes.Data.list;
      this.GetModelList();
    },
  },
  created() {
    this.GetInfo();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
/deep/ .van-pull-refresh__track {
  height: 100%;
  width: 100%;
}
</style>
